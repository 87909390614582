.ckw-admin-login-main-container {
    min-width: 100vw;
    max-width: 100vw;
    min-height: 100vh;
    max-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background:
    linear-gradient(27deg, #151515 5px, transparent 5px) 0 5px,
    linear-gradient(207deg, #151515 5px, transparent 5px) 10px 0px,
    linear-gradient(27deg, #222 5px, transparent 5px) 0px 10px,
    linear-gradient(207deg, #222 5px, transparent 5px) 10px 5px,
    linear-gradient(90deg, #1b1b1b 10px, transparent 10px),
    linear-gradient(#1d1d1d 25%, #1a1a1a 25%, #1a1a1a 50%, transparent 50%, transparent 75%, #242424 75%, #242424);
    background-color: #131313;
    background-size: 20px 20px;

    img {
        width: 100px;
        padding: 10px;
    }


    .admin-login-form {
        min-width: 25%;
        background-color: white;
        padding: 30px;
        box-shadow: 2px 2px 5px black;
        border-radius: 20px;
    }
}

// Media Query

@media screen and (max-width: 600px) {
    .ckw-admin-login-main-container {
        min-width: 100vw;
        max-width: 100vw;
        min-height: 100vh;
        max-height: 100vh;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background:
        radial-gradient(rgba(0, 0, 0, 0.247) 3px, transparent 4px),
        radial-gradient(rgba(0, 0, 0, 0.247) 3px, transparent 4px),
        linear-gradient(#fff 4px, transparent 0),
        linear-gradient(45deg, transparent 74px, transparent 75px, #a4a4a4 75px, #a4a4a4 76px, transparent 77px, transparent 109px),
        linear-gradient(-45deg, transparent 75px, transparent 76px, #a4a4a4 76px, #a4a4a4 77px, transparent 78px, transparent 109px),
        #fff;
        background-size: 109px 109px, 109px 109px,100% 6px, 109px 109px, 109px 109px;
        background-position: 54px 55px, 0px 0px, 0px 0px, 0px 0px, 0px 0px;


        .admin-login-form {
            min-width: 80%;
            background-color: white;
            padding: 30px;
            box-shadow: 2px 2px 5px black;
            border-radius: 20px;
        }
    }
}