.wh-admin-header-container {
    .wh-admin-navbar-container {
        box-shadow: 0px 0px 10px black;
    }
}

.wh-admin-header-middle-seperator {
    color: white;
    margin-top: 7px;
    margin-right: 10px;
    margin-left: 10px;
}