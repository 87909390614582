.product-report-main-container {
    display: flex;
    flex-direction: column;

    .glossary-view {
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        padding: 30px;

        .box-view {
            text-align: center;
            padding: 20px;
            border: 1px solid rgba(128, 128, 128, 0.295);
            border-radius: 10px;
            box-shadow: 10px 10px 10px gray;
        }
    }

    .search-view {
        padding: 30px;
        width: 70%;
    }

    .table-view {
        border-top: 1px solid gray;
        padding: 30px;
        display: flex;
        flex-direction: column;

        .download-table-xls-button {
            width: 150px;
            background-color: rgb(0, 0, 0);
            border: none;
            margin-bottom: 10px;
            box-shadow: 2px 2px 2px gray;
            font-size: 1rem;
            color: white;
            align-self: flex-end;
            border-radius: 5px;
        }
    }
}